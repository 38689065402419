import React from 'react';

import WarningIcon from '@material-ui/icons/Warning';
import { TestID } from '../../testID';
import { LinkButton } from 'Common/LinkButton/LinkButton';

export const AvatarConnectionString: React.FC<{
  customConnectionURL?: string;
  connectionUrl?: string;
  isConnected?: boolean;
  shouldDisplayRestoreLinkButton?: boolean;
  onRestoreConnectionClick: () => void;
}> = ({
  customConnectionURL,
  connectionUrl,
  isConnected,
  onRestoreConnectionClick,
  shouldDisplayRestoreLinkButton = false,
}) => (
  <>
    {customConnectionURL ? (
      <div>
        <div
          style={{ height: '14px', display: 'flex', alignItems: 'center', marginTop: 4 }}
          title={
            isConnected
              ? `Connected with custom connection string '${customConnectionURL}'`
              : `Will be connected with custom connection string '${customConnectionURL}'`
          }
          data-testid={TestID.AvatarCustomConnectionURL}
        >
          <WarningIcon fontSize="small" style={{ marginRight: 2, height: '14px' }} />{' '}
          <small>{customConnectionURL}</small>
        </div>
        {shouldDisplayRestoreLinkButton && (
          <LinkButton
            onClick={onRestoreConnectionClick}
            data-testid={TestID.RestoreDefaultConnectionLinkButton}
            style={{ fontSize: '.8rem' }}
          >
            Restore default connection
          </LinkButton>
        )}
      </div>
    ) : (
      <small title={connectionUrl} data-testid={TestID.AvatarDefaultConnectionURL}>
        {connectionUrl}
      </small>
    )}
  </>
);
