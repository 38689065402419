export const TestID = {
  AvatarCustomConnectionURL: 'avatar-custom-connection-url',
  AvatarDefaultConnectionURL: 'avatar-default-connection-url',
  BrowserListEntity: 'browser-list-entity',
  ConnectButton: 'connect-button',
  ConnectingNetworkLinkButton: 'connecting-network-link-button',
  ConnectionFailedMessage: 'connection-failed-message',
  ConnectionFailedMessageDetailsLinkButton: 'connection-failed-message-details-link-button',
  ConnectionFailedModal: 'connection-failed-modal',
  ConnectNetworkLinkButton: 'connect-network-link-button',
  CustomConnectionStringInput: 'custom-connection-string-input',
  DashboardSubscriptionCard: 'dashboard-subscription-card',
  DisconnectNetworkLinkButton: 'disconnect-network-link-button',
  DatasetInspectorFilter: 'dataset-inspector-filter',
  DatasetInspectorFilterAll: 'dataset-inspector-filter-all',
  DatasetInspectorFilterDeleted: 'dataset-inspector-filter-deleted',
  DatasetInspectorFilterLatest: 'dataset-inspector-filter-latest',
  LoginButton: 'login-button',
  LoginEmailAndPasswordRequired: 'login-email-and-password-required',
  LoginErrorMessageCaptchaRequired: 'login-error-message-captcha-required',
  LoginForm: 'login-form',
  LoginPasswordInput: 'login-password-input',
  LoginRecaptcha: 'login-recaptcha',
  LoginUserNameInput: 'login-user-name-input',
  LoginWrongCredential: 'login-wrong-credential',
  NetworkPolicyLink: 'network-policy-link',
  PersistConnectionStringCheckbox: 'persist-connection-string-checkbox',
  RestoreDefaultConnectionLinkButton: 'restore-default-connection-link-button',
  RepostAllButton: 'repost-all-button',
  postEntitiesModalTitle: 'post-entities-modal-title',
  postEntitiesModalSubTitle: 'post-entities-modal-sub-title',
  PostEntityModalPostButton: 'post-entity-modal-post-button',
  JsonEditorContainerDiv: 'json-editor-container-div',
  DatasetInspectorBrowserListEntityTitle: 'dataset-inspector-browser-list-entity-title',
  DatasetInspectorViewerEntityHeader: 'dataset-inspector-viewer-entity-header',
  ErrorMessageContainerOnDeadLetterPostModal: 'error-message-container-on-dead-letter-post-modal',
} as const;
