import React from 'react';
import PropTypes from 'prop-types';

import SesamLink from 'Common/Links/SesamLink';
import ArrowIcon from '../../../images/icons/arrow.svg';
import { SesamSystem } from './SesamSystem';
import PieHub from './PieHub';

import './SesamHub.css';

const getAngle = (idx, total) => {
  const totalEven = total % 2 === 0 ? total : total + 1;
  const separationAngle = 360 / Math.max(totalEven, 6);
  const degrees = Math.floor((idx - 2) / 4) * separationAngle + separationAngle;
  const direction = Math.abs(Math.floor((idx - 2) / 2) % 2) ? -1 : 1;

  return degrees * direction + 0; // get rid of negative zero
};

const nodeSorter = (nodeA, nodeB) => {
  if (nodeA.translate < nodeB.translate) {
    return -1;
  }
  if (nodeA.translate > nodeB.translate) {
    return 1;
  }

  if (nodeA.translate === nodeB.translate && nodeA.translate < 0) {
    if (nodeA.angle < nodeB.angle) {
      return 1;
    } else {
      return -1;
    }
  }

  if (nodeA.translate === nodeB.translate && nodeA.translate > 0) {
    if (nodeA.angle < nodeB.angle) {
      return -1;
    } else {
      return 1;
    }
  }
};

/*
 * Constructs the hub including the systems around it.
 */
const SesamHub = (props) => {
  const getColor = () => {
    return props.darkModeActive ? 'white' : 'black';
  };

  const nodes = () => {
    const L = props.systems.length;
    const sortedSystems = props.systems.slice().sort((s1, s2) => s1.name.localeCompare(s2.name));

    // this whole Array.from, sort, map is to have the
    // nodes be constructed on alternating sides but have
    // the systems sorted from top to bottom
    return Array.from({ length: L }, (x, i) => {
      const isEven = i % 2;
      return {
        idx: i,
        angle: getAngle(i, L),
        translate: `${isEven ? '' : '-'}10`,
        style: {
          marginLeft: !isEven ? '10%' : '',
          marginRight: isEven ? '10%' : '',
          transformOrigin: isEven ? '-12.5%' : '112.5%',
          right: isEven ? '0' : null,
          textAlign: isEven ? 'right' : null,
          display: 'flex',
          alignItems: 'center',
          flexDirection: isEven ? 'row-reverse' : 'row',
          alignSelf: isEven ? 'flex-start' : 'flex-end',
        },
      };
    })
      .sort(nodeSorter)
      .map(({ angle, translate, style, idx }, i) => {
        const system = sortedSystems[i];
        let className = 'spoke';
        className += ` ${system.branchColor}`;
        const nodeStyle = {
          transform: `rotate(${angle}deg) translateX(${translate}%)`,
          ...style,
        };
        switch (system.direction) {
          case 'inbound':
            className += ' spoke-inbound';
            break;

          case 'outbound':
            className += ' spoke-outbound';
            break;

          case 'both':
            className += ' spoke-inbound spoke-outbound';
            break;
          case 'none':
            break;
          default:
            throw new Error(`unknown direction`);
        }

        if (translate < 0) {
          className += ' left';
        } else {
          className += ' right';
        }

        const outboundLeft = translate < 0 && system.direction === 'outbound';
        const inboundRight = translate > 0 && system.direction === 'inbound';

        const inboundLeft = translate < 0 && system.direction === 'inbound';
        const outboundRight = translate > 0 && system.direction === 'outbound';

        const both = system.direction === 'both';

        return (
          <li className={className} style={nodeStyle} key={system.id}>
            <SesamSystem {...system} />
            <div className="arrow">
              {(outboundLeft || inboundRight || both) && (
                <ArrowIcon
                  style={{ color: system.branchColor || getColor() }}
                  className="arrow__head"
                />
              )}
              <div
                className="arrow__shaft"
                style={{
                  width: '58px',
                  height: '2px',
                  backgroundColor: system.branchColor || getColor(),
                }}
              />
              {(inboundLeft || outboundRight || both) && (
                <ArrowIcon
                  style={{ color: system.branchColor || getColor() }}
                  className="arrow__head arrow__head--flip"
                />
              )}
            </div>
          </li>
        );
      });
  };
  const slices = [
    {
      color: '#529cba', //blue
      name: 'Latest',
      onClick: () => {},
      value: props.sumLatest,
    },
    {
      color: '#c93c37', //red
      name: 'Deleted',
      onClick: () => {},
      value: props.sumDeleted,
    },
    {
      color: '#739984', //green
      name: 'History',
      onClick: () => {},
      value: props.sumHistory,
    },
  ];

  const globalDatasetLink =
    '/subscription/' + props.subscriptionId + '/pipes/all?Global.selected=Yes';
  return (
    <div>
      <section className="hub">
        <div className="nexus">
          <PieHub slices={slices}>
            <SesamLink to={globalDatasetLink}>
              <div style={{ color: 'black' }}>
                <h1 style={{ fontSize: '1.4rem' }}>SESAM</h1>
                <span>Datahub</span>
              </div>
            </SesamLink>
          </PieHub>
        </div>
        <ul className="wheel">{nodes()}</ul>
      </section>
    </div>
  );
};

SesamHub.propTypes = {
  sumLatest: PropTypes.number.isRequired,
  sumDeleted: PropTypes.number.isRequired,
  sumHistory: PropTypes.number.isRequired,
  systems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      direction: PropTypes.string.isRequired,
    })
  ),
  onPipeClick: PropTypes.func.isRequired,
  onDatasetClick: PropTypes.func.isRequired,
  onSystemClick: PropTypes.func.isRequired,
  subscriptionId: PropTypes.string.isRequired,
  darkModeActive: PropTypes.bool,
};

SesamHub.defaultProps = {
  systems: [],
};

export default SesamHub;
