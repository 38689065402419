import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';

import Flows from 'Internals/flows';
import SesamHub from '../../components/hub/SesamHub';
import DatasetActions, { datasetsSelector } from 'Redux/thunks/datasets';
import Legend from '../../../components/legend/Legend';
import OverviewInstructions from '../../../components/instructions-splash/instructions-overview';
import Page from '../../../components/page';
import PageHeader, { PageHeaderTitle } from '../../../components/page-header';
import PipeActions from 'Redux/thunks/pipes';
import SystemActions from 'Redux/thunks/systems';
import { pipesSelector, systemsSelector } from 'Redux/selectors';
import {
  getPipesFromSystem,
  getPipesToSystem,
  getPipesWithSystemTransform,
} from 'Internals/systems';
import { getIndexWithoutDeleted, getIndexDeleted, getLogDeleted } from 'Internals/datasets';
import { isValidConfig, isRunError } from 'Internals/pipes';
import { getBranchColor } from 'Internals/graph';

import './OverviewStyle.css';
import { LoadingPanel } from 'Common/LoadingPanel';
import pipes from 'Api/pipes';

const NODE_SYSTEM = 'system:sesam-node:sesam:node';

const isNotInternal = (system) => !system.isInternal;

const endpointTypes = ['http_endpoint', 'xml_endpoint', 'csv_endpoint', 'excel_endpoint'];

const coalescePipesIntoEndpointNodes = (acc, p) => {
  const sourceType = get(p, 'config.effective.source.type');
  const isConfigError = !isValidConfig(p);
  const isRunErr = isRunError(p);
  if (endpointTypes.includes(sourceType)) {
    if (acc[sourceType]) {
      if (acc[sourceType].pipesOut) acc[sourceType].pipesOut = acc[sourceType].pipesOut + 1;
      else acc[sourceType].pipesOut = 1;
      if (acc[sourceType].pipes) acc[sourceType].pipes.push(p['_id']);
      else acc[sourceType].pipes = [p['_id']];
      if (acc[sourceType].direction === 'outbound') acc[sourceType].direction = 'both';
      acc[sourceType].isInboundError = acc[sourceType].isInboundError || isConfigError || isRunErr;
    } else {
      acc[sourceType] = {
        pipesIn: 0,
        pipesOut: 1,
        direction: 'inbound',
        isInboundError: isConfigError || isRunErr,
        isOutboundError: false,
        pipes: [p['_id']],
      };
    }
  }
  const sinkType = get(p, 'config.effective.sink.type');
  if (endpointTypes.includes(sinkType)) {
    if (acc[sinkType]) {
      if (acc[sinkType].pipesIn) acc[sinkType].pipesIn = acc[sinkType].pipesIn + 1;
      else acc[sinkType].pipesIn = 1;
      if (acc[sinkType].pipes) acc[sinkType].pipes.push(p['_id']);
      else acc[sinkType].pipes = [p['_id']];
      if (acc[sinkType].direction === 'inbound') acc[sinkType].direction = 'both';
      acc[sinkType].isOutboundError = acc[sinkType].isOutboundError || isConfigError || isRunErr;
    } else {
      acc[sinkType] = {
        pipesOut: 0,
        pipesIn: 1,
        direction: 'outbound',
        isOutboundError: isConfigError || isRunErr,
        isInboundError: false,
        pipes: [p['_id']],
      };
    }
  }
  return acc;
};

function groupSystems(groups, system) {
  const groupName = system.systemGroup;
  const existingGroup = groups[groupName];

  if (existingGroup) {
    existingGroup.systems.push(system);
  } else {
    groups[groupName] = {
      id: groupName,
      systems: [system],
    };
  }
  return groups;
}

class Overview extends React.Component {
  constructor(props) {
    super(props);

    this.onDatasetClick = () => {
      this.context.router.push(`/subscription/${this.props.subId}/pipes`);
    };

    this.onInternalPipesClick = () => {
      this.context.router.push(
        `/subscription/${this.props.subId}/pipes?config.effective.source.system=${NODE_SYSTEM}&config.effective.sink.system=${NODE_SYSTEM}`
      );
    };

    this.onSystemClick = () => {
      this.context.router.push(`/subscription/${this.props.subId}/systems`);
    };

    this.state = {
      flows: null,
    };
  }

  componentDidMount() {
    this.props.loadEverything();
    Flows.get(this.props.pipes, this.props.downstreams, this.props.lookups).then((flows) => {
      this.setState({ flows });
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.pipes !== prevProps.pipes ||
      this.props.downstreams !== prevProps.downstreams ||
      this.props.lookups !== prevProps.lookups
    ) {
      Flows.get(this.props.pipes, this.props.downstreams, this.props.lookups).then((flows) => {
        this.setState({ flows });
      });
    }
  }

  render() {
    const truncate = (str, n) => (str.length > n ? `${str.substr(0, n - 4)} ...` : str);

    let systemNodes = this.props.systems.filter(isNotInternal).reduce(groupSystems, {});
    systemNodes = Object.values(systemNodes);

    systemNodes = systemNodes.map((group) => {
      const groupSystemIds = group.systems.map((s) => s._id);
      const groupSystemLinks = group.systems.map((s) => s.link);
      let pipesFrom = [];
      let pipesTo = [];
      let hasRestTransforms = false;

      for (const system of group.systems) {
        let _pipesFrom = getPipesFromSystem(system._id, this.props.pipes, this.props.inbounds);
        let _pipesTo = getPipesToSystem(system._id, this.props.pipes, this.props.outbounds);

        pipesFrom = pipesFrom.concat(_pipesFrom);
        pipesTo = pipesTo.concat(_pipesTo);

        // to show pipes with rest transform in the chart 
        const pipesWithSystemTransform = getPipesWithSystemTransform(
          system._id,
          this.props.pipes,
          this.props.transforms
        );
        hasRestTransforms = pipesWithSystemTransform.length !== 0 ? true : false;
        pipesFrom = uniqBy(pipesFrom.concat(pipesWithSystemTransform), (pipe) => pipe._id);
        pipesTo = uniqBy(pipesTo.concat(pipesWithSystemTransform), (pipe) => pipe._id);
      }

      let branchColor = this.props.darkModeActive ? 'white' : 'black';
      if (this.state.flows) {
        const flowsFromToSystems = this.state.flows
          .filter((flow) => {
            if (flow.kind === 'system') {
              return groupSystemIds.includes(flow.system);
            } else return false;
          })
          .map((flow) => flow.path);

        const allPipesInFlows = flowsFromToSystems.reduce((acc, flow) => {
          flow.forEach((node) => {
            if (!acc.includes(node)) {
              acc.push(node);
            }
          });
          return acc;
        }, []);

        const allPipesOutsideFlows = [...pipesFrom, ...pipesTo]
          .filter((pipe) => !allPipesInFlows.includes(pipe['_id']))
          .map((p) => p['_id']);

        branchColor = getBranchColor(
          flowsFromToSystems,
          allPipesOutsideFlows,
          this.props.pipes,
          this.props.darkModeActive
        );
      }

      const isOutboundError = pipesFrom.reduce((acc, pipe) => {
        const isConfigError = !isValidConfig(pipe);
        const isRunErr = isRunError(pipe);
        return acc && isConfigError && isRunErr;
      }, false);

      const isInboundError = pipesTo.reduce((acc, pipe) => {
        const isConfigError = !isValidConfig(pipe);
        const isRunErr = isRunError(pipe);
        return acc && isConfigError && isRunErr;
      }, false);

      let direction;
      if (pipesFrom.length > 0 && pipesTo.length > 0) {
        direction = 'both';
      } else if (pipesTo.length > 0) {
        direction = 'outbound';
      } else if (pipesFrom.length > 0) {
        direction = 'inbound';
      } else {
        direction = 'none';
      }

      let pipesFromQueryParams;
      if (hasRestTransforms)
        pipesFromQueryParams = groupSystemLinks.map((l) => `Transforms.selected=${l}`).join('&');
      else pipesFromQueryParams = groupSystemLinks.map((l) => `From.selected=${l}`).join('&');

      let pipesFromLink = `/subscription/${this.props.subId}/pipes/all?${pipesFromQueryParams}`;

      let pipesToQueryParams;
      if (hasRestTransforms)
        pipesToQueryParams = groupSystemLinks.map((l) => `Transforms.selected=${l}`).join('&');
      else pipesToQueryParams = groupSystemLinks.map((l) => `To.selected=${l}`).join('&');

      const pipesToLink = `/subscription/${this.props.subId}/pipes/all?${pipesToQueryParams}`;

      let link = '';
      if (groupSystemIds.length === 1) {
        link = `/subscription/${this.props.subId}/systems/system/${groupSystemLinks[0]}/overview`;
      } else {
        const queryParams = groupSystemLinks.map((l) => `System.selected=${l}`).join('&');

        link = `/subscription/${this.props.subId}/systems/all?${queryParams}`;
      }

      return {
        id: group.id,
        name: truncate(group.id, 18),
        pipesFrom: pipesFrom.length,
        pipesTo: pipesTo.length,
        direction,
        pipesFromLink,
        pipesToLink,
        link,
        isInboundError,
        isOutboundError,
        branchColor,
      };
    });

    // systemNodes = makeGroups(systemNodes);

    const endpointsMap = Object.values(this.props.pipes) // TODO fix this
      .filter(isNotInternal)
      .reduce(coalescePipesIntoEndpointNodes, {});

    const endpointNodes = Object.entries(endpointsMap).map(([k, v]) => {
      let branchColor = getBranchColor([], v.pipes, this.props.pipes, this.props.darkModeActive);
      return {
        id: k,
        name: k,
        direction: v.direction,
        pipesFrom: v.pipesOut,
        pipesTo: v.pipesIn,
        link: '',
        pipesFromLink: `/subscription/${this.props.subId}/pipes/all?Source type.selected=${k}&Origin.selected=user`,
        pipesToLink: `/subscription/${this.props.subId}/pipes/all?Sink type.selected=${k}&Origin.selected=user`,
        isInboundError: v.isInboundError,
        isOutboundError: v.isOutboundError,
        branchColor,
      };
    });

    systemNodes = [...systemNodes, ...endpointNodes];

    const sumLatest = this.props.datasets.reduce(
      (sum, dataset) => sum + getIndexWithoutDeleted(dataset),
      0
    );

    const sumDeleted = this.props.datasets.reduce(
      (sum, dataset) => sum + getIndexDeleted(dataset),
      0
    );

    const sumHistory = this.props.datasets.reduce(
      (sum, dataset) => sum + getLogDeleted(dataset),
      0
    );

    if (!this.props.everythingLoaded) {
      return <LoadingPanel />;
    } else if (!this.props.hasUserDatasets || !this.props.hasUserPipes) {
      return (
        <Page dataSelenium="subscription-overview-pageview">
          <OverviewInstructions
            subId={this.props.subId}
            hasUserDatasets={this.props.hasUserDatasets}
            hasUserPipes={this.props.hasUserPipes}
            hasUserSystems={this.props.hasUserSystems}
          />
        </Page>
      );
    } else if (this.props.everythingLoaded) {
      return (
        <Page dataSelenium="subscription-overview-pageview">
          <PageHeader>
            <PageHeaderTitle>Overview</PageHeaderTitle>
          </PageHeader>
          <div className="scrollArea overview">
            <div className="overview__legend">
              <Legend
                items={[
                  {
                    shape: 'block',
                    label: 'Deleted entities',
                    color: 'rgb(181, 99, 112)',
                  },
                  {
                    shape: 'block',
                    label: 'History',
                    color: 'rgb(115, 153, 132)',
                  },
                  {
                    shape: 'block',
                    label: 'Latest entities',
                    color: 'rgb(82, 156, 186)',
                  },
                  { shape: 'line', label: 'Pipe error', color: '#c93c37' },
                  { shape: 'line', label: 'Pipe running', color: 'green' },
                  { shape: 'line', label: 'Pipe disabled', color: 'lightgray' },
                ]}
              />
            </div>
            <SesamHub
              sumLatest={sumLatest}
              sumDeleted={sumDeleted}
              sumHistory={sumHistory}
              onDatasetClick={this.onDatasetClick}
              onPipeClick={this.onInternalPipesClick}
              onSystemClick={this.onSystemClick}
              subscriptionId={this.props.subId}
              systems={systemNodes}
              darkModeActive={this.props.darkModeActive}
            />
          </div>
        </Page>
      );
    }
  }
}

Overview.propTypes = {
  datasets: PropTypes.array.isRequired,
  pipes: PropTypes.shape({}).isRequired,
  downstreams: PropTypes.shape({}).isRequired,
  lookups: PropTypes.shape({}).isRequired,
  inbounds: PropTypes.shape({}).isRequired,
  outbounds: PropTypes.shape({}).isRequired,
  subId: PropTypes.string.isRequired,
  systems: PropTypes.array.isRequired,
  everythingLoaded: PropTypes.bool.isRequired,
  hasUserDatasets: PropTypes.bool.isRequired,
  hasUserPipes: PropTypes.bool.isRequired,
  hasUserSystems: PropTypes.bool.isRequired,
  loadEverything: PropTypes.func.isRequired,
  darkModeActive: PropTypes.bool,
  transforms: PropTypes.object,
};

Overview.contextTypes = {
  // https://github.com/reactjs/react-router/issues/975
  router: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    datasets: datasetsSelector(state),
    everythingLoaded:
      state.loadStatus.datasetsLoaded &&
      state.loadStatus.pipesLoaded &&
      state.loadStatus.systemsLoaded,
    hasUserDatasets:
      state.loadStatus.datasetsLoaded &&
      datasetsSelector(state).some((dataset) => !dataset.isInternal),
    hasUserPipes:
      state.loadStatus.pipesLoaded && pipesSelector(state).some((pipe) => !pipe.isInternal),
    hasUserSystems:
      state.loadStatus.systemsLoaded && systemsSelector(state).some((system) => !system.isInternal),
    pipes: state.pipes,
    downstreams: state.downstreams,
    lookups: state.lookups,
    inbounds: state.inbounds,
    outbounds: state.outbounds,
    subId: state.subscription.id,
    systems: systemsSelector(state),
    darkModeActive: state.theme.dark,
    transforms: state.transforms,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadEverything: () =>
      Promise.all([
        dispatch(DatasetActions.loadAll()),
        dispatch(PipeActions.loadAll()),
        dispatch(SystemActions.loadAll()),
      ]),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
