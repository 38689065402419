import PropTypes from 'prop-types';
import React from 'react';
import mapObject from 'lodash/map';
import { withTheme } from '@material-ui/core/styles';

import { buildClassName } from 'Internals/react-utils';

import './style.css';
import { TestID } from '../../testID';

// --- Clickbox behaviour ------------------------------------------------------

const clickboxInteractiveElements = [
  'A',
  'AUDIO',
  'BUTTON',
  'DETAILS',
  'IFRAME',
  'INPUT',
  'LABEL',
  'SELECT',
  'TEXTAREA',
  'VIDEO',
];

const handleOnClick = (onClickProp) => (ev) => {
  if (!clickboxInteractiveElements.includes(ev.target.tagName)) {
    onClickProp(ev);
  }
};

// -----------------------------------------------------------------------------

function Card({
  actions,
  asideActions,
  badges,
  classNames,
  header,
  onClick,
  text,
  type,
  theme,
  testid,
}) {
  const cardThemeStyle = {
    backgroundColor: theme.palette.background.light,
    border: `1px solid ${
      type === 'notification' ? theme.palette.primary.main : theme.palette.divider
    }`,
  };

  return (
    <article
      className={buildClassName(`card card--${type}`, onClick && 'card--clickbox', classNames)}
      onClick={onClick && handleOnClick(onClick)}
      style={cardThemeStyle}
      data-testid={testid}
    >
      {badges && Object.keys(badges).length > 0 && (
        <div className="card__badges">
          {mapObject(badges, (badge, badgeKey) => (
            <div
              key={badgeKey}
              className={`badge badge--${badgeKey}`}
              style={{
                backgroundColor: theme.palette.background.semilight,
                color: theme.palette.text.primary,
              }}
            >
              {badge}
            </div>
          ))}
        </div>
      )}
      <div className="card__header" style={{ backgroundColor: theme.palette.background.light }}>
        {header}
      </div>
      <div className="card__text" style={{ backgroundColor: theme.palette.background.light }}>
        {text}
      </div>
      {(actions || asideActions) && (
        <div
          className="card__actions"
          style={{
            backgroundColor:
              type === 'notification'
                ? theme.palette.background.blueish
                : theme.palette.background.light,
            borderTop: `1px solid ${theme.palette.divider}`,
          }}
        >
          {asideActions && <div className="card__actions-aside">{asideActions}</div>}
          {actions}
        </div>
      )}
    </article>
  );
}

Card.propTypes = {
  actions: PropTypes.node,
  asideActions: PropTypes.node,
  badges: PropTypes.shape(),
  classNames: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  header: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  testid: PropTypes.string,
  text: PropTypes.node,
  type: PropTypes.oneOf(['default', 'success', 'notification']),
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      background: PropTypes.shape({
        light: PropTypes.string,
        blueish: PropTypes.string,
        semilight: PropTypes.string,
      }),
      divider: PropTypes.string,
      primary: PropTypes.shape({ main: PropTypes.string }),
      text: PropTypes.shape({ primary: PropTypes.string }),
    }),
  }),
};

Card.defaultProps = {
  actions: undefined,
  asideActions: undefined,
  badges: undefined,
  classNames: undefined,
  onClick: undefined,
  testid: TestID.DashboardSubscriptionCard,
  text: undefined,
  type: 'default',
};

export default withTheme(Card);
