import { Theme as MUITheme } from '@material-ui/core';
import { PaymentMethod } from '../api/payment-methods';

export type VoidCallback = (callback: Function) => void;

export interface ApiConf {
  subUrl: string;
  token: string;
}

/**
 * Represents a ui-operations that the user can potientially perform. If the user is allowed to perform the operations, the 'enabled'-property will be true. If the user is not allowed to do the operation, the 'enabled'-property will be false, and the 'reason-code' property will explain why the operation is not allowed.
 */
export interface SupportedOperation {
  enabled: boolean;
  id: string;
  name: string;
  'reason-code'?: string;
  'reason-msg'?: string;
}

export interface Theme {
  palette: {
    primary: {
      main: '';
    };
    error: {
      main: '';
      dark: '';
    };
  };
}

export type CustomTheme = MUITheme & {
  palette: {
    background: {
      light: string;
    };
  };
};

export interface User {
  announcements: [];
  approvedTos: number;
  captchaRequired: boolean;
  createSubFailed: boolean;
  email: string;
  emailVerified: boolean;
  forgotEmailSent: boolean;
  isPortalAdmin: false;
  lastCreateTrialRequest: null | Date; // Not sure if Date is right
  loggedIn: boolean;
  loginFailed: boolean;
  name: string;
  passwordReset: boolean;
  providerIds: string[];
  providerWhitelist: string[];
  signupFailed: boolean;
  supportedOperations: {
    activate_portal_admin_powers: SupportedOperation;
    create_paymentmethod: SupportedOperation;
    create_subscription: SupportedOperation;
    create_trial_subscription: SupportedOperation;
    delete_account: SupportedOperation;
    request_email_verification: SupportedOperation;
    request_trial_subscription: SupportedOperation;
    send_reset_password_email: SupportedOperation;
    update_user_profile: SupportedOperation;
  };
  user_id: string;
  zendeskAvailable: boolean;
}

export interface SomeObject {
  [key: string]: any;
}

// Note, this might not be useful, because of how Typescript treats
// index types in objects/arrays (can't use type aliases in them)
export type _ID = string;

export type PipeID = _ID;

export type DatasetID = _ID;

export type SystemID = _ID;

export interface UpstreamMap {
  [datasetId: string]: PipeID;
}

export interface DownstreamMap {
  [datasetId: string]: {
    [pipeId: string]: true;
  };
}

export interface LookupMap {
  [datasetId: string]: {
    [pipeId: string]: true;
  };
}

export interface InboundMap {
  [sourceSystemId: string]: {
    [pipeId: string]: true;
  };
}

export interface OutboundMap {
  [sinkSystemId: string]: {
    [pipeId: string]: true;
  };
}

export interface TransformMap {
  [tranformSystemId: string]: {
    [pipeId: string]: true;
  };
}

export type PipePredicate = (p: Pipe) => boolean;

export interface NodeComponent {
  _id: _ID;
}

type ConfigError = {
  elements: string;
  msg: string;
  level: string;
};

export interface PipeRuntime {
  queues: { source: SomeObject; dependencies: SomeObject };
  'last-started': string | null;
  'last-run': string | null;
  'next-run': string | null;
  'last-message': string | null;
  'last-traceback': string | null;
  'last-original-error-message': string | null;
  'last-original-traceback': string | null;
  'average-process-time': number | null;
  'is-running': boolean;
  'is-disabled': boolean;
  'disable-set-last-seen': boolean;
  'supports-since': boolean;
  state: string | null;
  success: boolean;
  'current-time': string;
  'is-valid-config': boolean;
  'config-errors': ConfigError[];
  progress: {
    processed: number | null;
    'total-estimate': number | null;
    remaining: number | null;
    'last-run': number | null;
    'processed-by-group'?: SomeObject;
    total_estimate_by_group?: SomeObject;
  };
  metrics: SomeObject;
}

export type ValueOf<T> = T[keyof T];

export type pipeFields = Array<'config' | 'runtime' | 'storage' | 'graph'>;
export interface PipeResponse {
  _id: string;
  subjectidentifier?: string;
  config?: {
    original: SomeObject;
    effective: SomeObject;
    audit: SomeObject | null;
  };
  runtime: PipeRuntime | Pick<PipeRuntime, 'success' | 'is-running'>;
}

export interface Pipe extends NodeComponent {
  name: string; // we add this in the decoratePipe function
  runtime?: {
    dependencies?: string[];
    'is-running': boolean;
    'is-valid-config': boolean;
    'is-disabled'?: boolean;
    'config-errors'?: ConfigError[];
    queues?: SomeObject;
  };

  config: {
    original: PipeConfig;
    effective: {
      _id: PipeID;
      type: 'pipe';
      source: { type: string; dataset?: string; datasets?: string[] };
      pump: SomeObject;
      sink: { type: string; dataset?: string };
      principals?: string[];
      transform?: object;
    };
  };
  cached_response_info?: CachedResponseInfo;
  actionStatus: { status: string };
}

export type PipeConfig = {
  _id: string;
  type: 'pipe';
  metadata?: {
    '$config-group'?: string;
    dataset?: string;
  };
};

export type Optional<T> = T | null | undefined;

export interface PipeMap {
  [key: string]: Pipe;
}

interface DatasetRuntimeQueues {
  size: number;
  index: number;
  populated: true;
}

export interface DatasetRuntime {
  'last-modified': Optional<string>;
  queues: DatasetRuntimeQueues | [];
  'circuit-breaker-tripped'?: boolean;
}

export interface Dataset extends NodeComponent {
  cached_response_info?: CachedResponseInfo;
  runtime: DatasetRuntime;
}
export interface DatasetMap {
  [key: string]: Dataset;
}
export type DatasetIndex = SomeObject;

interface SystemConfigError {
  msg: string;
  elements: string;
  level: 'error' | 'warning';
}
export interface SystemRuntime {
  'is-valid-config': boolean;
  'config-errors': SystemConfigError[];
  'has-sink-configs': Optional<boolean>;
  'has-source-configs': Optional<boolean>;
  origin: Optional<string>;
  version: any; // it's anything that is in the metadata version property
}

export interface System extends NodeComponent {
  _id: SystemID;
  name: string;
  runtime: SystemRuntime;
  config: SystemConfig;
  configGroup: string;
  systemGroup: string;
  subjectidentifier: string;
  cached_response_info?: CachedResponseInfo;
  secrets?: string[];
}
export interface SystemMap {
  [key: string]: System;
}

export interface SystemConfig {
  _id: SystemID;
  metadata?: {
    /**
     * This can be set to specify that this pipe belongs to the specified config-group. If this value is not set, the pipe belongs to the default config-group. See also the '/config/{config-group}' endpoint.
     */
    '$config-group'?: string;
  };
}

export type CachedResponseInfo = {
  /**
   * The date and time the information was cached.
   */
  timestamp: string;
  /**
   * The age (in seconds) of the cached information. This information can be derived from the "cache_time" property, but is included as a debug-aid.
   */
  age: number;
};

export type Model = SomeObject;

export type EntityType = SomeObject;

// these are straight from the swagger generated code
export interface definitions {
  /**
   * In a multinode setting the aggragator-node's API might in some cases return cached information about a subnode, instead of a live response from the subnode. This happens if the subnode is temporarily not responding. If this happens, a 'cached_response_info' property will be added to the item in order to notifiy the user that the information might not be up-to-date.
   */

  Pump: SomeObject;
  PipeEntity: { _id: string };
  ConfigEntity: { _id: string; type: string };

  DatasetEntity: {
    _id: string;
    _updated: number;
  };
  License: SomeObject;
  Namespaces: SomeObject;
  ServiceMetadata: SomeObject;
  /**
   * A list of secret-keys
   */
  SecretKeys: string[];
  /**
   * A secret-key => secret-value mapping. Both keys and values is strings.
   */
  SecretValues: SomeObject;
  /**
   * A shareable link to a publisher endpoint.
   */
  ShareableLink: {
    id: string;
    /**
     * The url(s) of this shareable link. There can be more than one url, since some publishers have more than one url. The sdshare publisher for instance has a "/sdshare-collection" and a a "/sdshare-fragments" url.
     */
    urls: string[];
    /**
     * The expiration-time (in seconds since the Epoch) of this shareable link.
     */
    exp: number;
    /**
     * Unix timestamp when the shareable link was created (nanoseconds)
     */
    created_ts: string;
    /**
     * Description of the shareable link given by the user to help categorizing and remembering.
     */
    description: string;
  };
  /**
   * The user-specified information required for creating a new shareable link for a publisher endpoint.
   */
  NewShareableLink: {
    /**
     * The expiration_time (in seconds since the Epoch) of the new shareable link.
     */
    exp?: number;
    /**
     * Description of the shareable link given by the user to help categorizing and remembering.
     */
    description?: string;
  };
}

/* Auditing information about an entity. */
export interface AuditInfo {
  created_ts?: string;
  created_by?: {
    email?: string;
    user_id?: string;
    picture?: string;
    name?: string;
  };
  last_modified_ts?: string;
  last_modified_by?: {
    email?: string;
    user_id?: string;
    picture?: string;
    name?: string;
  };
}

/* A JWT api token that has been created for one subscription */
export interface SubscriptionJWT {
  id: string;
  name: string;
  description: string;
  'supported-operations': {
    delete_api_jwt_token: SupportedOperation & {
      'reason-code'?: 'user_is_not_admin' | 'email_not_verified' | 'portal_is_in_readonly_mode';
    };
  };
  payload: {
    api_token_id: string;
    iat: number;
    exp: number;
    user_id: string;
    user_profile: {
      email?: string;
      name?: string;
      picture?: string;
    };
    principals: SomeObject;
    user_principal?: 'group:Everyone';
  };
  jwt_string?: string;
  client_id?: string;
  client_secret?: string;
  refresh_interval?: number;
  audit: AuditInfo;
}

interface NetworkACLEntry {
  access: 'Allow' | 'Deny';
  address: string;
}

interface Products {
  datahub?: {
    size: 'small' | 'medium' | 'large' | 'enterprise';
    maximum_store_disk_usage?: number;
    backup?: 'local-replicated' | 'geo-replicated';
    monitoring?: 'standard';
    version?: string;
  };
  gdpr_platform?: {
    size: 'embedded';
    custom_fqdn?: string[];
    name?: string;
  };
  databrowser?: {
    id: string;
    name?: string;
    size?: 'embedded' | 'basic' | 'standard' | 'enterprise';
    type?: 'gdpr';
    custom_fqdn?: string[];
  }[];
  microservices?: { size: 'small' | 'medium' | 'large' | 'enterprise' };
  sla?: { level: 'basic' | 'standard' | 'enterprise' };
  vpn?: {
    'public-gateway'?: string;
    'onsite-ranges'?: string[];
    'local-range'?: string;
    'local-subnet'?: string;
    type?: 'route-based';
    dns?: string[];
  };
}

interface ProvisioningResults {
  bootstrap?: boolean;
  msg?: string[];
  network?: {
    acl?: NetworkACLEntry[];
    subnet?: string;
    range?: string;
    ['public-access']?: boolean;
    [key: string]: any;
  };
  datahub?: {
    ['public_ip']: string;
    fqdn: string;
    ['private-ip']: string;
    debuginfo?: { [key: string]: any };
    tag?: string;
  };
  services?: {
    databrowsers?: {
      id: string;
      type: 'databrowser';
      ['public_ip']: string;
      fqdn: string;
      ['custom_fqdn']?: string[];
      ['privete-ip']: string;
      size?: string;
      tag?: string;
      debuginfo?: SomeObject;
    };
  }[];
  ['dev_docker_container_info']?: SomeObject;
  [key: string]: any;
}

export interface License {
  ['is-active-license']: boolean;
  original: {
    created: string;
    email: string;
    _id: string;
  };
  signed: string;
}

interface NetworkSettings {
  'public-access'?: boolean;
  'network-acl'?: { access: 'Allow' | 'Deny'; address: string }[];
}

type SubscriptionUpdated = SomeObject;

interface SubscriptionConnections {
  name: string;
  id: string;
  type: 'sesam-node' | 'databrowser';
  url: string;
}

type SubscriptionDescription = string;

export interface SubscriptionMemberInfo {
  _updated?: { [key: string]: any };
  roles?: string[];
  audit?: {
    invited_ts?: string;
    invited_by?: {
      email?: string;
      user_id?: string;
      picture?: string;
      name?: string;
    };
  };
  sesam_support_info?: {
    exp: string;
    iat?: string;
    reason?: { [key: string]: any };
  };
  user?: {
    email?: string;
    name?: string;
    picture?: string;
    user_id?: string;
  };
}

type SubscriptionPaymentMethodId = string;

type SubscriptionService = 'in-cloud' | 'on-premise';

type SubscriptionType = 'trial' | 'paid';

type SubscriptionURL = string;

export interface Subscription {
  id: string;
  _updated: SubscriptionUpdated;
  name: string;
  type: SubscriptionType;
  created: string;
  licenses: License[];
  ['provisioning_status']?: 'pending' | 'completed' | 'provisioning' | 'failed' | 'destroyed';
  ['is_unused_precreated_subscription']?: boolean;
  ['was_created_from_a_precreated_subscription']?: boolean;
  url?: SubscriptionURL;
  description?: SubscriptionDescription;
  ['provisioning_result']?: ProvisioningResults;
  products: Products;
  network?: NetworkSettings;
  connections?: SubscriptionConnections;
  service?: SubscriptionService;
  ['paymentmethod_id']?: SubscriptionPaymentMethodId;
  ['paymentmethod_info']?: PaymentMethod;
  ['current-users-member']: SomeObject & SubscriptionMemberInfo;
  audit?: AuditInfo;
  'supported-operations'?: {
    view_api_jwt_tokens?: SupportedOperation & {
      'reason-code'?:
        | 'user_is_not_admin'
        | 'not_logged_in'
        | 'user_does_not_have_required_permission'
        | 'email_not_verified'
        | 'portal_is_in_readonly_mode';
    };
    add_api_jwt_token?: SupportedOperation & {
      'reason-code'?:
        | 'user_is_not_admin'
        | 'not_logged_in'
        | 'user_does_not_have_required_permission'
        | 'email_not_verified'
        | 'portal_is_in_readonly_mode';
    };
    create_custom_role: SupportedOperation & {
      'reason-code'?:
        | 'not_logged_in'
        | 'user_does_not_have_required_permission'
        | 'email_not_verified'
        | 'portal_is_in_readonly_mode';
    };
    assign_role_to_user: SupportedOperation & {
      'reason-code'?:
        | 'not_logged_in'
        | 'user_does_not_have_required_permission'
        | 'email_not_verified'
        | 'portal_is_in_readonly_mode';
    };
    modify_subscription: SupportedOperation & {
      'reason-code'?:
        | 'not_logged_in'
        | 'user_does_not_have_required_permission'
        | 'email_not_verified'
        | 'portal_is_in_readonly_mode';
    };
    delete_subscription: SupportedOperation & {
      'reason-code'?:
        | 'not_logged_in'
        | 'user_from_jwt'
        | 'user_does_not_have_required_permission'
        | 'user_is_not_last_subscription_member'
        | 'email_not_verified'
        | 'portal_is_in_readonly_mode';
    };
    view_members: SupportedOperation & {
      'reason-code'?: 'not_logged_in' | 'user_does_not_have_required_permission';
    };
    leave_subscription: SupportedOperation & {
      'reason-code'?:
        | 'is_last_admin'
        | 'is_paymentmethod_owner'
        | 'email_not_verified'
        | 'portal_is_in_readonly_mode'
        | 'user_from_jwt';
    };
    invite_user: SupportedOperation & {
      'reason-code'?:
        | 'not_logged_in'
        | 'user_does_not_have_required_permission'
        | 'email_not_verified'
        | 'portal_is_in_readonly_mode';
    };
    accept_invitation: SupportedOperation & {
      'reason-code'?: 'no_invite' | 'email_not_verified' | 'portal_is_in_readonly_mode';
    };
    decline_invitation: SupportedOperation & {
      'reason-code'?:
        | 'no_invite'
        | 'already_declined'
        | 'email_not_verified'
        | 'portal_is_in_readonly_mode';
    };
    add_notification_rule: SupportedOperation & {
      'reason-code'?:
        | 'not_logged_in'
        | 'user_does_not_have_required_permission'
        | 'email_not_verified'
        | 'portal_is_in_readonly_mode';
    };
    renew_license?: SupportedOperation & {
      'reason-code'?:
        | 'not_logged_in'
        | 'user_does_not_have_required_permission'
        | 'license_expiration_date_is_too_far_in_the_future'
        | 'is_trial_subscription_and_user_is_not_portal_admin'
        | 'email_not_verified'
        | 'portal_is_in_readonly_mode';
    };
  };
}

export interface RoleInfo {
  name?: string;
  description?: string;
  id?: string;
  ['child-roles']?: string[];
}

export interface SearchFacet {
  name: string;
  count?: number;
}

export interface SearchResult {
  id: string;
  dataset: string;
  refs?: string[];
  ids?: string[];
}

export interface SearchAggregationResults {
  results: SearchFacet[];
}

export interface SearchResults {
  total_hits: number | null;
  results: SearchResult[];
}

export const CustomURLConnectionStatus = {
  NotConnected: 'not-connected',
  Connected: 'connected',
  Failed: 'failed',
};
